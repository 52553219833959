import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { VerticalAlignBottomOutlined, ProfileOutlined, RightOutlined, PartitionOutlined } from '@ant-design/icons-vue';
import { selectJobCascade } from '@/api/material';
import { selectDepartmentList } from '@/api/personnel';
import { updateCancelAppointment, selectAppointmentByPagination, selectVehicleAppointmentList, selectVehicleAppointmentById, updateToApproval } from '@/api/administration';
import Cookies from "js-cookie";
import { selectApprovalItem } from '@/api/material';
const columns = [{
  title: '车牌号',
  dataIndex: 'licensePlate',
  fixed: 'left'
}, {
  title: '品牌型号',
  dataIndex: 'brandModel'
}, {
  title: '类型',
  dataIndex: 'vehicleType',
  slots: {
    customRender: 'vehicleType'
  }
}, {
  title: '驾驶员',
  dataIndex: 'driverName'
}, {
  title: '申请人',
  dataIndex: 'vehicleUserName'
}, {
  title: '所在部门',
  dataIndex: 'departmentName'
}, {
  title: '用车时间',
  dataIndex: 'time',
  width: '320px',
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: '200px',
  slots: {
    customRender: 'operation'
  }
}];
const recordColumns = [{
  title: '申请人',
  dataIndex: 'vehicleUserName'
}, {
  title: '所在部门',
  dataIndex: 'departmentName'
}, {
  title: '用车时间',
  dataIndex: 'time',
  width: '305px',
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}];
export default {
  components: {
    VerticalAlignBottomOutlined,
    ProfileOutlined,
    RightOutlined,
    PartitionOutlined
  },
  emits: ['closeMakeVehicle'],

  setup(props, context) {
    const handleBack = () => {
      context.emit('closeMakeVehicle', false);
    };

    const formState = reactive({
      vehicleType: null,
      licensePlate: null,
      brandModel: null,
      driverName: null,
      numOrWeight: null,
      userUid: null,
      departmentId: null,
      state: null
    });
    const formRef = ref(); //表格

    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const tableState = reactive({
      selectedRowKeys: [],
      tableLoading: false
    });

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        vehicleType: formState.vehicleType,
        licensePlate: formState.licensePlate,
        brandModel: formState.brandModel,
        driverName: formState.driverName,
        numOrWeight: formState.numOrWeight,
        userUid: formState.userUid,
        departmentId: formState.departmentId,
        state: formState.state
      };
      tableState.tableLoading = true;

      try {
        let res = await selectAppointmentByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id,
              time: `${item.startTime}-${item.endTime}`
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          tableState.tableLoading = false;
        } else {
          tableState.tableLoading = false;

          _message.error(res.message);
        }
      } catch {
        tableState.tableLoading = false;
      }
    }; //点击查询


    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    };

    const belongProject = Number(Cookies.get("belongProject"));

    const handleLing = val => {
      console.log(11111111, val);
      formState.userUid = val ? val[1] : null; // // console.log(formState.uid)

      formState.departmentId = val ? val[0] : null;
    }; //部门信息


    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //岗位及人员


    const useReturnOptions = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobProjectsAll = res.data.jobProjectsAll;
        useReturnOptions.value = conversion(jobProjectsAll);
      }
    };

    const conversion = val => {
      const arr = [];
      let myMap = {};
      val.forEach(item => {
        // let obj = {
        //   value: item.departmentId,
        //   label: item.departmentName,
        //   children: []
        // }
        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            let obj = {
              value: ixt.uid,
              label: ixt.userName,
              departmentId: ixt.departmentId
            };

            if (!myMap[ixt.departmentId]) {
              let obj1 = {
                value: ixt.departmentId,
                label: ixt.departmentName,
                children: []
              };
              obj1.children.push(obj);
              arr.push(obj1);
              myMap[ixt.departmentId] = ixt.departmentId;
            } else {
              // console.log('arr',arr)
              arr.forEach(ixts => {
                if (ixts.value === ixt.departmentId) {
                  ixts.children.push(obj);
                }
              });
            } // arr.push({...ixt})

          });
        } // arr.push(obj)

      }); // console.log(arr)

      return arr;
    }; //取消预约


    const onCancel = async id => {
      let res = await updateCancelAppointment({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const weekOption = [{
      value: 1,
      label: '星期一'
    }, {
      value: 2,
      label: '星期二'
    }, {
      value: 3,
      label: '星期三'
    }, {
      value: 4,
      label: '星期四'
    }, {
      value: 5,
      label: '星期五'
    }, {
      value: 6,
      label: '星期六'
    }, {
      value: 0,
      label: '星期日'
    }];

    const getTagData = val => {
      let arr = [];
      val.forEach(item => {
        arr.push(weekOption.find(ixt => item === ixt.value));
      });
      return arr;
    }; //预约记录


    const recordTableData = ref([]);
    const recordVisible = ref(false);

    const toViewRecord = async () => {
      let res = await selectVehicleAppointmentList({
        vehicleId: formStateForMake.id
      });

      if (res.code === 200) {
        recordTableData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            recordTableData.value.push({ ...item,
              time: `${item.startTime}-${item.endTime}`
            });
          });
        }

        recordVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const makeVisible = ref(false);
    const makeTitle = ref('预约详情');
    const formRefForMake = ref();
    const formStateForMake = reactive({
      knVehicle: {
        licensePlate: null,
        vehicleType: null,
        numOrWeight: null,
        brandModel: null,
        driverName: null,
        usetimeSet: 0,
        weekTime: [],
        dateTime: [],
        vehicleImg: [],
        remark: null
      },
      vehicleUserName: null,
      startTime: null,
      departurePlace: null,
      departmentName: null,
      endTime: null,
      destination: null,
      remark: null,
      content: null,
      state: null,
      itemId: null,
      approvalDtos: [],
      hyApproachApprovals: []
    });

    const toView = async (id, num) => {
      let res = await selectVehicleAppointmentById(id);

      if (res.code == 200) {
        Object.assign(formStateForMake, res.data);
        formStateForMake.knVehicle.vehicleImg = JSON.parse(res.data.knVehicle.vehicleImg);
        formStateForMake.knVehicle.weekTime = [];
        formStateForMake.knVehicle.dateTime = [];

        if (res.data.knVehicle.usetimeSet === 0) {
          if (res.data.knVehicle && res.data.knVehicle.usetimeSetList && res.data.knVehicle.usetimeSetList.length) {
            res.data.knVehicle.usetimeSetList.forEach(item => {
              formStateForMake.knVehicle.weekTime.push({
                date: JSON.parse(item.weekSet),
                startTime: item.startTime,
                endTime: item.endTime
              });
            });
          }
        } else {
          if (res.data.knVehicle && res.data.knVehicle.usetimeSetList && res.data.knVehicle.usetimeSetList.length) {
            res.data.knVehicle.usetimeSetList.forEach(item => {
              formStateForMake.knVehicle.dateTime.push({
                date: [item.startDate, item.endDate],
                startTime: item.startTime,
                endTime: item.endTime
              });
            });
          }
        }

        formStateForMake.state = num;

        if (num === 0) {
          makeTitle.value = '预约详情';
        } else {
          makeTitle.value = '预约审批';
        }

        makeVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const handleCancelMake = () => {
      makeVisible.value = false;
    };

    const setKaoDetailLoading = ref(false);
    const setKaoDetailLoading1 = ref(false);

    const handleUpdateMake = async state => {
      if (!formStateForMake.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      if (state === 0) {
        setKaoDetailLoading1.value = true;
      } else {
        setKaoDetailLoading.value = true;
      }

      try {
        let param = {
          applyId: formStateForMake.id,
          content: formStateForMake.content,
          state,
          belongProject: Number(Cookies.get("belongProject"))
        };
        let res = await updateToApproval(param);

        if (res.code === 200) {
          _message.success(res.message);

          setKaoDetailLoading.value = false;
          setKaoDetailLoading1.value = false;
          makeVisible.value = false;
          getTableData();
        }
      } catch (error) {
        setKaoDetailLoading.value = false;
        setKaoDetailLoading1.value = false;
      }
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 18,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "审批责任人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";

        case 4:
          return "财务审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    onMounted(() => {
      getTableData();
      getJobCascade();
      getDepartmentList();
      getApprovalItem();
    });
    return {
      handleBack,
      columns,
      tableData,
      loading,
      onPageChange,
      paginationConfig,
      formState,
      formRef,
      onSubmit,
      resetForm,
      belongProject,
      useReturnOptions,
      handleLing,
      departmentData,
      onCancel,
      formStateForMake,
      weekOption,
      getTagData,
      recordTableData,
      recordVisible,
      toViewRecord,
      recordColumns,
      makeVisible,
      makeTitle,
      formRefForMake,
      toView,
      handleCancelMake,
      setKaoDetailLoading,
      setKaoDetailLoading1,
      handleUpdateMake,
      projectOptions,
      getCurrent,
      setStatus,
      setRoleType
    };
  }

};