import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import { selectVehicleByPagination, addOrUpdateVehicle, selectVehicleById, deleteVehicle, selectVehicleAppointmentList, addVehicleAppointment } from '@/api/administration';
import { useStore } from 'vuex';
import { QuestionCircleOutlined, LeftCircleOutlined, RightCircleOutlined, DeleteOutlined, PlusCircleOutlined, DownOutlined } from '@ant-design/icons-vue';
import makeVehicle from './components/makeVehicle.vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import moment from 'moment';
import getFieldRules from '@/utils/fieldRules';
import { selectWarntime, updateWarntime, selectVerifierSetting, selectApprovalItem, setTemporaryTransferState, setTemporaryTransfer, selectApproachByModular, selectJobCascade, deleteVerifierSetting } from '@/api/material';
import vehiclConf from './components/vehiclConf.vue'; //表头

const columns = [{
  title: '类型',
  dataIndex: 'vehicleType',
  slots: {
    customRender: 'vehicleType'
  },
  width: 80,
  align: 'center'
}, {
  title: '车牌号',
  dataIndex: 'licensePlate',
  align: 'center'
}, {
  title: '品牌型号',
  dataIndex: 'brandModel'
}, {
  title: '驾驶员',
  dataIndex: 'driverName' // align:'center'

}, {
  title: '载客人数/载客量',
  dataIndex: 'numOrWeight',
  width: 150,
  align: 'center'
}];
const recordColumns = [{
  title: '申请人',
  dataIndex: 'vehicleUserName'
}, {
  title: '所在部门',
  dataIndex: 'departmentName'
}, {
  title: '用车时间',
  dataIndex: 'time',
  width: '305px',
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}];
const responsibleColumns = [{
  title: '名称',
  dataIndex: 'itemName'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const transferColumns = [{
  title: '原审批责任人',
  dataIndex: 'sourceName'
}, {
  title: '临时审批责任人',
  dataIndex: 'targetName'
}, {
  title: '转移期限',
  dataIndex: 'time'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    LeftCircleOutlined,
    RightCircleOutlined,
    makeVehicle,
    uploadImgs,
    DeleteOutlined,
    PlusCircleOutlined,
    DownOutlined,
    vehiclConf
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      vehicleType: null,
      licensePlate: null,
      brandModel: null,
      driverName: null,
      numOrWeight: null
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([{}]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        vehicleType: formState.vehicleType,
        licensePlate: formState.licensePlate,
        brandModel: formState.brandModel,
        driverName: formState.driverName,
        numOrWeight: formState.numOrWeight
      };
      loading.value = true;
      let res = await selectVehicleByPagination(param);

      if (res.code === 200) {
        const arr = [];
        tableData.value = [];
        if (res.data.list) res.data.list.forEach(item => {
          let obj = { ...item,
            key: item.id
          };

          if (isShowDetail.value && item.id == idDetail.value) {
            obj.isSelected = true;
          }

          arr.push(obj);
        });
        Object.assign(tableData.value, arr);
        total.value = res.data.countSize;
        current.value = res.data.nowPage;
        pageSize.value = res.data.pageSize;
        loading.value = false;
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    };

    let idDetail = ref(null);

    const customRow = val => {
      return {
        onClick: event => {
          // console.log(event,val)
          tableData.value.forEach(item => {
            item.isSelected = false;
          });
          val.isSelected = true;
          getDetailData(val.key);
          idDetail.value = val.key;
        }
      };
    };

    const rowClassName = record => {
      const defaultClass = ['pointer'];
      record.isSelected && defaultClass.push('row-background');
      return defaultClass.join(' ');
    }; //车辆详情


    const tableDetail = reactive({
      id: null,
      licensePlate: null,
      vehicleImg: [],
      usetimeSet: null,
      remark: null,
      weekTime: [],
      dateTime: []
    });
    const isShowDetail = ref(false);

    const getDetailData = async id => {
      let res = await selectVehicleById(id);

      if (res.code === 200) {
        idDetail.value = res.data.id;
        Object.assign(tableDetail, res.data);
        tableDetail.vehicleImg = [];
        tableDetail.vehicleImg = JSON.parse(res.data.vehicleImg);
        tableDetail.weekTime = [];
        tableDetail.dateTime = [];

        if (res.data.usetimeSet === 0) {
          res.data.usetimeSetList.forEach(item => {
            tableDetail.weekTime.push({
              date: JSON.parse(item.weekSet),
              startTime: item.startTime,
              endTime: item.endTime,
              dateStr: getWeekStr(JSON.parse(item.weekSet))
            });
          });
        } else {
          res.data.usetimeSetList.forEach(item => {
            tableDetail.dateTime.push({
              date: [item.startDate, item.endDate],
              startTime: item.startTime,
              endTime: item.endTime,
              startDate: item.startDate,
              endDate: item.endDate
            });
          });
        }

        isShowDetail.value = false;
        nextTick(() => {
          isShowDetail.value = true;
        });
      } else {
        _message.error(res.message);
      }
    };

    const getWeekStr = arr => {
      let newArr = [];
      arr.forEach(item => {
        newArr.push(weekOption.find(ixt => ixt.value == item).label);
      });
      return newArr.join(',');
    }; //修改


    const addTitle = ref('添加车辆');

    const setDetail = () => {
      Object.assign(formStateForModel, tableDetail);
      addTitle.value = '修改车辆';
      addVisible.value = true;
    }; //预约记录


    const recordTableData = ref([]);
    const recordVisible = ref(false);

    const toViewRecord = async () => {
      let res = await selectVehicleAppointmentList({
        vehicleId: tableDetail.id
      });

      if (res.code === 200) {
        recordTableData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            recordTableData.value.push({ ...item,
              time: `${item.startTime}-${item.endTime}`
            });
          });
        }

        recordVisible.value = true;
      } else {
        _message.error(res.message);
      }
    }; //预约车辆列表


    const makeVehicleVisible = ref(false);

    const handleMakeVehicle = () => {
      makeVehicleVisible.value = true;
    };

    const closeMakeVehicle = val => {
      makeVehicleVisible.value = false;
    }; //添加车辆


    const {
      checkName,
      checkPhone,
      checkCard
    } = getFieldRules();
    const formRefForModel = ref();
    const formStateForModel = reactive({
      vehicleImg: [],
      usetimeSet: 0,
      weekTime: [{
        date: [],
        startTime: '',
        endTime: ''
      }],
      dateTime: [{
        date: [],
        startTime: '',
        endTime: ''
      }],
      licensePlate: null,
      brandModel: null,
      vehicleType: null,
      driverName: null,
      numOrWeight: null,
      remark: null,
      id: null
    });
    const addVisible = ref(false);
    const rules = {
      usetimeSet: [{
        required: true,
        message: '可用时间不能为空',
        trigger: 'change',
        type: 'number'
      }],
      licensePlate: [{
        required: true,
        message: '车牌号不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      brandModel: [{
        required: true,
        message: '品牌类型不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      numOrWeight: [{
        required: true,
        message: '载客人数/载重量不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      vehicleType: [{
        required: true,
        message: '类型不能为空',
        trigger: 'change',
        type: 'number'
      }],
      driverName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      sourceUid: [{
        required: true,
        message: '原审批责任人不能为空',
        trigger: 'change'
      }],
      targetUid: [{
        required: true,
        message: '临时转移人不能为空',
        trigger: 'change',
        type: 'array'
      }],
      time: [{
        required: true,
        message: '转移期限不能为空',
        trigger: 'change',
        type: 'array'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };

    const toAddView = () => {
      formStateForModel.vehicleImg = [];
      formStateForModel.usetimeSet = 0;
      formStateForModel.weekTime = [{
        date: [],
        startTime: '',
        endTime: ''
      }];
      formStateForModel.dateTime = [{
        date: [],
        startTime: '',
        endTime: ''
      }];
      formStateForModel.licensePlate = null;
      formStateForModel.brandModel = null;
      formStateForModel.vehicleType = null;
      formStateForModel.driverName = null;
      formStateForModel.numOrWeight = null;
      formStateForModel.remark = null;
      formStateForModel.id = null;
      nextTick(() => {
        addTitle.value = '添加车辆';
        addVisible.value = true;
      });
    };

    const closeAddVisible = () => {
      formRefForModel.value.resetFields();
      addVisible.value = false;
    };

    const addLoading = ref(false);

    const handleAddOk = () => {
      console.log(formStateForModel);
      formRefForModel.value.validate().then(async () => {
        // if(!formStateForModel.usetimeSet) {
        //   let arr = formStateForModel.weekTime
        //   let leng = formStateForModel.weekTime.length
        //   if( leng >=2) {
        //     for(let i = 0; i <leng; i++ ) {
        //       for (let j = 0; j < leng; j++) {
        //       let result= arr[i].date.some((item) => arr[j].date.includes(item))
        //       if(result) {
        //         let is = new Date(`2022-12-08 ${arr[i].startTime}`);
        //         let ie = new Date(`2022-12-08 ${arr[i].endTime}`);
        //         let js = new Date(`2022-12-08 ${arr[j].startTime}`);
        //         let je =new Date(`2022-12-08 ${arr[j].endTime}`);
        //         if (i == j) {
        //           if((is == ie)) {
        //             message.error('可用时间有重复，请重新选择！')
        //             return;
        //           }else {
        //             continue
        //           }
        //         } else {
        //           if ((is <= je && is >= js) || (ie >= js && ie <= je) || (is <= js && ie >= je) || (is >= js && ie <=
        //           je)) {
        //             message.error('可用时间有重复，请重新选择！')
        //             return;
        //           }else{
        //             return false
        //           }
        //         }
        //       }
        //       }
        //     }
        //   }
        // }
        let param = {};
        Object.assign(param, formStateForModel);
        param.vehicleImg = JSON.stringify(param.vehicleImg);
        let usetimeSetList = [];

        if (param.usetimeSet === 0) {
          param.weekTime.forEach(item => {
            usetimeSetList.push({
              weekSet: item.date,
              startTime: item.startTime,
              endTime: item.endTime
            });
          });
        } else {
          param.dateTime.forEach(item => {
            usetimeSetList.push({
              startDate: item.date[0],
              endDate: item.date[1],
              startTime: item.startTime,
              endTime: item.endTime
            });
          });
        }

        param.usetimeSetList = usetimeSetList;
        addLoading.value = true;

        try {
          let res = await addOrUpdateVehicle(param);

          if (res.code === 200) {
            _message.success(res.message);

            addLoading.value = false;
            formRefForModel.value.resetFields();
            addVisible.value = false;

            if (isShowDetail.value) {
              getDetailData(idDetail.value);
            }

            getTableData();
          } else {
            _message.error(res.message);

            addLoading.value = false;
          }
        } catch (error) {
          addLoading.value = false;
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const addTime = () => {
      console.log(formStateForModel.usetimeSet);

      if (formStateForModel.usetimeSet == 0) {
        formStateForModel.weekTime.push({
          date: [],
          startTime: '',
          endTime: ''
        });
      } else {
        formStateForModel.dateTime.push({
          date: [],
          startTime: '',
          endTime: ''
        });
      }
    };

    const deleteTime = index => {
      if (formStateForModel.usetimeSet == 0) {
        if (formStateForModel.weekTime.length == 1) {
          return _message.error('可用时间不可全部删除！');
        }

        formStateForModel.weekTime.splice(index, 1);
      } else {
        if (formStateForModel.dateTime.length == 1) {
          return _message.error('可用时间不可全部删除！');
        }

        formStateForModel.dateTime.splice(index, 1);
      }
    };

    const weekOption = [{
      value: 1,
      label: '星期一'
    }, {
      value: 2,
      label: '星期二'
    }, {
      value: 3,
      label: '星期三'
    }, {
      value: 4,
      label: '星期四'
    }, {
      value: 5,
      label: '星期五'
    }, {
      value: 6,
      label: '星期六'
    }, {
      value: 0,
      label: '星期日'
    }];

    const getTagData = val => {
      let arr = [];
      val.forEach(item => {
        arr.push(weekOption.find(ixt => item === ixt.value));
      });
      return arr;
    };

    const getdisabledHours = val => {
      return () => {
        let hours = [];
        let time = val;
        let timeArr = time.split(':');

        for (let i = 0; i < parseInt(timeArr[0]); i++) {
          hours.push(i);
        }

        return hours;
      };
    };

    const getdisabledMinutes = val => {
      return hour => {
        let time = val;
        let timeArr = time.split(':');
        let minutes = [];

        if (hour == parseInt(timeArr[0])) {
          for (let i = 0; i <= parseInt(timeArr[1]); i++) {
            minutes.push(i);
          }
        }

        return minutes;
      };
    }; //获取附件的url


    const getAdjuncts = val => {
      formStateForModel.vehicleImg = val;
    }; // 预约用车弹框


    const makeVisible = ref(false);
    const formRefForMakeModel = ref();
    const formStateForMakeModel = reactive({
      userUid: Cookies.get('uid'),
      name: Cookies.get('nickName'),
      startTime: null,
      endTime: null,
      // approvals:[[]], //审批人员
      remark: null,
      destination: null,
      departurePlace: null,
      itemId: null
    });

    const toMakeVehicle = () => {
      getApprovalItem();
    };

    const closeMakeVisible = () => {
      formRefForMakeModel.value.resetFields();
      makeVisible.value = false;
    };

    const makeLoading = ref(false);

    const handleMakeOk = () => {
      formRefForMakeModel.value.validate().then(async () => {
        // console.log(formStateForMakeModel)
        if (new Date(formStateForMakeModel.startTime) >= new Date(formStateForMakeModel.endTime)) {
          _message.error('结束时间必须大于开始时间');

          return;
        } // let arr = formStateForMakeModel.approvals
        // let arr1 =[]
        // arr.forEach(item => {
        //   arr1.push(item[1])
        // })
        // arr1 = [...new Set(arr1)]
        // if(arr1.length !== arr.length) {
        //   message.error('审批人员不能重复！')
        //     return
        // }


        const param = {
          // approvalSettingList: [],
          vehicleId: tableDetail.id
        };
        Object.assign(param, formStateForMakeModel); // arr.forEach(item => {
        //   param.approvalSettingList.push({uid:item[1]})
        // })

        makeLoading.value = true;

        try {
          let res = await addVehicleAppointment(param);

          if (res.code == 200) {
            _message.success(res.message);

            makeLoading.value = false;
            makeVisible.value = false;
          } else {
            _message.error(res.message);

            makeLoading.value = false;
          }
        } catch (error) {
          makeLoading.value = false;
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const getdepartmentName = computed(() => {
      let name = "";
      useReturnOptions.value.forEach(item => {
        item.children.forEach(ixt => {
          if (ixt.value == Cookies.get('uid')) {
            name = item.label;
            return;
          }
        });
      });
      return name;
    }); //部门及人员

    const useReturnOptions = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobProjectsAll = res.data.jobProjectsAll;
        useReturnOptions.value = conversion(jobProjectsAll);
      }
    };

    const conversion = val => {
      const arr = [];
      let myMap = {};
      val.forEach(item => {
        // let obj = {
        //   value: item.departmentId,
        //   label: item.departmentName,
        //   children: []
        // }
        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            let obj = {
              value: ixt.uid,
              label: ixt.userName,
              departmentId: ixt.departmentId
            };

            if (!myMap[ixt.departmentId]) {
              let obj1 = {
                value: ixt.departmentId,
                label: ixt.departmentName,
                children: []
              };
              obj1.children.push(obj);
              arr.push(obj1);
              myMap[ixt.departmentId] = ixt.departmentId;
            } else {
              // console.log('arr',arr)
              arr.forEach(ixts => {
                if (ixts.value === ixt.departmentId) {
                  ixts.children.push(obj);
                }
              });
            } // arr.push({...ixt})

          });
        } // arr.push(obj)

      }); // console.log(arr)

      return arr;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const range = (start, end) => {
      const result = [];

      for (let i = start; i < end; i++) {
        result.push(i);
      }

      return result;
    };

    const disabledDateTime = dates => {
      let hours = moment().hours(); //0~23

      let minutes = moment().minutes(); //0~59

      let seconds = moment().seconds(); //0~59
      //当日只能选择当前时间之后的时间点

      if (dates && moment(dates).date() === moment().date()) {
        if (moment(dates).hours() > moment().hours()) {
          return {
            disabledHours: () => range(1, hours),
            disabledMinutes: () => range(),
            disabledSeconds: () => range()
          };
        }

        return {
          disabledHours: () => range(1, hours),
          disabledMinutes: () => range(1, minutes + 1),
          disabledSeconds: () => range(1, seconds + 1)
        };
      }
    };

    const handleDate = val => {
      // console.log(val,moment(val),moment(),moment(val)<moment())
      if (moment(val) < moment()) {
        formStateForMakeModel.startTime = moment().format('YYYY-MM-DD HH:mm');
      }
    };

    const handleDate1 = val => {
      console.log(val);

      if (moment(val) < moment()) {
        formStateForMakeModel.endTime = moment().format('YYYY-MM-DD HH:mm');
      }
    };

    const addApproval = () => {
      formStateForMakeModel.approvals.push([]);
    };

    const deleteApproval = index => {
      if (formStateForMakeModel.approvals.length === 1) {
        _message.error('审批人员不能为空');

        return;
      }

      formStateForMakeModel.approvals.splice(index, 1);
    }; //删除车辆


    const deleteMakeVehicleLoading = ref(false);

    const deleteMakeVehicle = async id => {
      try {
        deleteMakeVehicleLoading.value = true;
        let res = await deleteVehicle({
          id
        });

        if (res.code == 200) {
          deleteMakeVehicleLoading.value = false;

          _message.success(res.message);

          isShowDetail.value = false;
          idDetail.value = null;
          getTableData();
        } else {
          deleteMakeVehicleLoading.value = false;

          _message.error(res.message);
        }
      } catch (error) {
        deleteMakeVehicleLoading.value = false;
      }
    };

    const handleUsetimeSet = val => {
      if (formStateForModel.usetimeSet == 0) {
        formStateForModel.dateTime = [{
          date: [],
          startTime: '',
          endTime: ''
        }];
      } else {
        formStateForModel.weekTime = [{
          date: [],
          startTime: '',
          endTime: ''
        }];
      }
    }; //责任人配置


    const respVisible = ref(false);
    const respData = reactive({
      rests: [],
      itemId: null,
      itemName: null
    });

    const handleConf = async () => {
      const param = {
        type: 18,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        const arr = [];
        const arr1 = [];
        projectByResponsible.value = [];
        transferData.value = [];
        if (res.data.approvalItems && res.data.approvalItems.length) res.data.approvalItems.forEach(item => {
          arr.push({
            key: item.id,
            ...item
          });
        });
        Object.assign(projectByResponsible.value, arr);
        if (res.data.approvalTransfers && res.data.approvalTransfers.length) res.data.approvalTransfers.forEach(item => {
          arr1.push({
            key: item.id,
            ...item,
            time: `${item.startTime}-${item.endTime}`
          });
        });
        Object.assign(transferData.value, arr1);
        responsibleVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const responsibleConf = itemId => {
      Promise.all([getVerifierSetting(itemId)]).then(res => {
        // console.log('Promise.all',res)
        try {
          respData.rests = getJobData(res[0].rests);
          respData.itemId = res[0].itemId;
          respData.itemName = res[0].itemName;
          nextTick(() => {
            respVisible.value = true;
          });
        } catch (error) {
          console.log(error);
        }
      });
    };

    const getJobData = arr => {
      let respData = [];
      arr.forEach(item => {
        respData.push([item.jobId, `${item.idCard}-${item.projectId}-${item.userName}`]);
      });
      return respData;
    }; // 查询审批责任人信息


    const getVerifierSetting = id => {
      return new Promise((resolve, reject) => {
        const param = {
          type: 18,
          belongProject: Number(Cookies.get("belongProject")),
          itemId: id ? id : null
        };
        selectVerifierSetting(param).then(res => {
          resolve(res.data);
        }).catch(error => {
          console.log('查询审批责任人信息', error);
        });
      });
    }; //关闭责任人弹窗


    const closeRespVisible = val => {
      respVisible.value = val;
      handleConf();
    }; //审批责任人项目列表


    const responsibleVisible = ref(false);
    const projectByResponsible = ref([]);
    const transferData = ref([]); //取消权限转移

    const cancelTransfer = async id => {
      let res = await setTemporaryTransferState({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        handleConf();
      } else {
        _message.error(res.message);
      }
    }; //新增权限转移


    const addTransferVisible = ref(false);

    const addTransfer = () => {
      addTransferVisible.value = true;
    };

    const formRefForModelTransfer = ref(false);
    const formStateForModelTransfer = reactive({
      sourceUid: null,
      targetUid: [],
      time: []
    });

    const handleCancelTransfer = () => {
      formRefForModelTransfer.value.resetFields();
      addTransferVisible.value = false;
    };

    const adTransferLoading = ref(false);

    const handleOkTransfer = () => {
      console.log(formStateForModelTransfer);
      formRefForModelTransfer.value.validate().then(async () => {
        adTransferLoading.value = true;

        try {
          let param = {
            sourceUid: formStateForModelTransfer.sourceUid,
            targetUid: formStateForModelTransfer.targetUid[1],
            startTime: formStateForModelTransfer.time[0],
            endTime: formStateForModelTransfer.time[1],
            modularType: 18,
            belongProject: Number(Cookies.get("belongProject"))
          };
          let res = await setTemporaryTransfer(param);

          if (res.code === 200) {
            _message.success(res.message);

            adTransferLoading.value = false;
            formRefForModelTransfer.value.resetFields();
            addTransferVisible.value = false;
            handleConf();
          } else {
            _message.error(res.message);

            adTransferLoading.value = false;
          }
        } catch (error) {
          console.log(error);
          adTransferLoading.value = false;
        }
      });
    };

    const transferOption = ref([]);

    const getTransferOption = async () => {
      let param = {
        type: 18,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApproachByModular(param);

      if (res.code === 200) {
        transferOption.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            transferOption.value.push({
              value: item.idCard,
              label: item.userName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const personnelData = ref([]);

    const getPersonnelData = async val => {
      let param = {
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectJobCascade(param);

      if (res.code === 200) {
        const jobProjectsAll = res.data.jobProjectsAll;
        personnelData.value = conversion1(jobProjectsAll); // if(res.data && res.data.length) {
        //   res.data.forEach(item => {
        //     personnelData.value.push({
        //       value: item.idCard,
        //       label: item.userName,
        //     })
        //   })
        // }
      } else {
        _message.error(res.message);
      }
    };

    const conversion1 = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            obj.children.push({
              value: ixt.uid,
              label: ixt.userName,
              projectId: ixt.belongProject
            });
          });
        }

        arr.push(obj);
      });
      return arr;
    };

    const deleteConf = async itemId => {
      try {
        let param = {
          itemId,
          modularType: 18
        };
        let res = await deleteVerifierSetting(param);

        if (res.code == 200) {
          _message.success(res.message);

          handleConf();
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    }; //审批时间设置


    const timeVisible = ref(false);
    const timeFormRef = ref();
    const timeFormState = reactive({
      intervalTime: null,
      circulateTime: null,
      id: null,
      type: null,
      belongProject: null,
      isOpenOvertime: false
    });

    const handleTimeConf = async () => {
      const param = {
        type: 18,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectWarntime(param);

      if (res.code === 200) {
        Object.assign(timeFormState, res.data);
        timeFormState.isOpenOvertime = timeFormState.isOpenOvertime ? true : false;
        timeVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const setTimeloading = ref(false);

    const handleSetTime = () => {
      timeFormRef.value.validate().then(async () => {
        // console.log(timeFormState)
        if (timeFormState.isOpenOvertime && timeFormState.circulateTime <= timeFormState.intervalTime) {
          _message.error('流转时间必须大于提示时间！');

          return;
        }

        const param = {};
        Object.assign(param, timeFormState);
        param.isOpenOvertime = param.isOpenOvertime ? 1 : 0;
        setTimeloading.value = true;
        let res = await updateWarntime(param);

        if (res.code === 200) {
          _message.success(res.message);

          timeVisible.value = false;
          setTimeloading.value = false;
        } else {
          _message.error(res.message);

          setTimeloading.value = false;
        }
      }).catch(error => {
        console.log('error', error);
        setTimeloading.value = false;
      });
    };

    const handleCancel = () => {
      timeVisible.value = false;
      timeFormRef.value.resetFields();
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 18,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }

        makeVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getTableData();
      getJobCascade();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      // toView,
      customRow,
      rowClassName,
      isCompanyAdmin,
      toViewRecord,
      recordVisible,
      recordColumns,
      recordTableData,
      makeVehicleVisible,
      handleMakeVehicle,
      closeMakeVehicle,
      formRefForModel,
      formStateForModel,
      addVisible,
      rules,
      closeAddVisible,
      addLoading,
      handleAddOk,
      getAdjuncts,
      toAddView,
      addTime,
      deleteTime,
      weekOption,
      getTagData,
      getdisabledHours,
      getdisabledMinutes,
      makeVisible,
      formRefForMakeModel,
      formStateForMakeModel,
      closeMakeVisible,
      makeLoading,
      handleMakeOk,
      getdepartmentName,
      toMakeVehicle,
      disabledDate,
      disabledDateTime,
      handleDate,
      handleDate1,
      addApproval,
      deleteApproval,
      deleteMakeVehicle,
      deleteMakeVehicleLoading,
      handleUsetimeSet,
      setDetail,
      isShowDetail,
      tableDetail,
      addTitle,
      useReturnOptions,
      responsibleVisible,
      projectByResponsible,
      responsibleColumns,
      responsibleConf,
      transferColumns,
      transferData,
      cancelTransfer,
      addTransfer,
      addTransferVisible,
      formRefForModelTransfer,
      formStateForModelTransfer,
      handleCancelTransfer,
      handleOkTransfer,
      adTransferLoading,
      transferOption,
      getTransferOption,
      personnelData,
      getPersonnelData,
      deleteConf,
      handleConf,
      respVisible,
      closeRespVisible,
      respData,
      timeVisible,
      timeFormRef,
      timeFormState,
      handleTimeConf,
      setTimeloading,
      handleSetTime,
      handleCancel,
      projectOptions
    };
  }

});